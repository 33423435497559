// import {
//   getCommonParamsOptions,
//   getGlobalParamsOptionsAsync,
//   getParamsOptionsAsync
// } from '@/utils/common'

export const baseViewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'general.name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: {
        xl: 16,
        lg: 16,
        md: 16,
        sm: 16,
        xs: 16
      },
      otherOptions: {
        disabled: true
      }
    }
  ],
  validateRules: {}
}
